import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  GridList,
} from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import Reference from '../components/reference';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecorationLine: 'none',
  },
  container: {
    paddingTop: theme.spacing(2),
  },
}));

const References = ({ data }) => {
  const references = data.allContentfulReference.nodes;
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="references" />
      <Container>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={2} sm={1}>
            <Link to="/" className={classes.link} aria-label="home">
              <Typography color="secondary" variant="h3" component="h2">
                &lt;
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={10} sm={11}>
            <Typography color="secondary" variant="h3" component="h1">
              References
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={12}>
            <GridList spacing={16}>
              {references.map((reference) => (
                <Reference reference={reference} key={reference.id} />
              ))}
            </GridList>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

References.propTypes = {};

export default References;

export const pageQuery = graphql`
  query {
    allContentfulReference {
      nodes {
        name
        id
        link
        image {
          fixed(height: 200, width: 300) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
  }
`;
