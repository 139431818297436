import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { CssBaseline, makeStyles } from '@material-ui/core';
import Navigation from './navigation';
import Footer from './footer';
import './layout.css';
import CookieConsent from 'react-cookie-consent';

const useStyles = makeStyles((theme) => ({
  site: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#000000',
  },
  siteContent: {
    flexGrow: '1',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1),
  },
  gdprButtons: {
    backgroundColor: 'inherit',
    color: '#FFFFFF',
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Helmet>
      <CssBaseline />
      <div className={classes.site}>
        <Navigation />
        <div className={classes.siteContent}>{children}</div>
        <Footer />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton={true}
        cookieName="gatsby-gdpr-google-analytics"
        buttonStyle={{ backgroundColor: 'inherit', color: '#FFFFFF' }}
        declineButtonStyle={{ backgroundColor: 'inherit', color: '#FFFFFF' }}
        style={{ backgroundColor: 'black' }}
      >
        This site uses cookies ...
      </CookieConsent>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
