import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import Link from './link';

const useStyles = makeStyles((theme) => ({
  activeLink: {
    backgroundColor: theme.palette.secondary.dark,
  },
  buttonRoot: {
    justifyContent: 'flex-start',
  },
}));

const MenuButton = ({ to, children }) => {
  const classes = useStyles();
  return (
    <Link
      to={to}
      color="secondary"
      activeClassName={classes.activeLink}
      partiallyActive={true}
    >
      <Button color="secondary" className={classes.buttonRoot}>
        {children}
      </Button>
    </Link>
  );
};

MenuButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default MenuButton;
