import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  makeStyles,
  Paper,
  GridList,
  GridListTile,
  GridListTileBar,
  Link,
} from '@material-ui/core';
import Img from 'gatsby-image';

const Reference = ({ reference }) => {
  return (
    <Link href={reference.link} target="_blank">
      <GridListTile key={reference.id}>
        {reference.image && reference.image.fixed && (
          <Img fixed={reference.image.fixed} />
        )}
        <GridListTileBar title={reference.name} />
      </GridListTile>
    </Link>
  );
};

Reference.propTypes = {
  reference: PropTypes.any.isRequired,
};

export default Reference;
