import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from './link';
import { makeStyles, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  contact: {
    textAlign: 'left',
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <>
      <div className={classes.footer}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs></Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-evenly"
            >
              <Grid item xs={6}>
                <Link to="/" aria-label="home">
                  <Img fixed={data.logo.childImageSharp.fixed} />
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} className={classes.contact}>
                  <Grid item xs={12}>
                    <Typography color="secondary">8audio</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="secondary">BE0820.516.268</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="secondary">
                      Roterijstraat 58, 8500 Kortrijk
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs></Grid>
        </Grid>
      </div>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
