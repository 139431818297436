import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql, navigate } from 'gatsby';
import Block from '../components/block';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecorationLine: 'none',
  },
}));
const onBack = () => {
  navigate(-1);
};

const BlockLayout = ({ data }) => {
  const block = data.contentfulTextBlock;
  const classes = useStyles();
  return (
    <Layout>
      <SEO title={block.slug} />
      <Container>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={2} sm={1}>
            <Button onClick={onBack} aria-label="home">
              <Typography color="secondary" variant="h3" component="h2">
                &lt;
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={10} sm={11}></Grid>
        </Grid>
        <Block block={block} key={block.id} />
      </Container>
    </Layout>
  );
};

export default BlockLayout;

export const blockQuery = graphql`
  query($slug: String!) {
    contentfulTextBlock(slug: { eq: $slug }) {
      id
      slug
      text {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 100, format: HTML)
        }
      }
      image {
        fluid(maxWidth: 220) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
