import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Menu,
  MenuItem,
  Hidden,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Link from './link';
import MenuButton from './menuButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import MuiLink from '@material-ui/core/Link';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { Location, useLocation } from '@reach/router';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    paddingRight: theme.spacing(2),
  },
  social: {
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Navigation = (props) => {
  const { pathname } = useLocation();

  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pages: allContentfulHomepage(limit: 1) {
        nodes {
          pages {
            title
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  const menuItems = data.pages.nodes[0].pages;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <span className={classes.logoContainer}>
          <Link to="/" className={classes.logo} aria-label="home">
            <Img fixed={data.logo.childImageSharp.fixed} />
          </Link>
          <MuiLink
            href="https://www.facebook.com/8audiocompany"
            className={classes.social}
            rel="noopener"
            target="_blank"
            aria-label="facebook"
          >
            <FacebookIcon fontSize="large" />
          </MuiLink>
          <MuiLink
            href="https://www.instagram.com/8audiocompany"
            className={classes.social}
            rel="noopener"
            target="_blank"
            aria-label="instagram"
          >
            <InstagramIcon fontSize="large" />
          </MuiLink>
          <MuiLink
            href="mailto:info@8audio.be"
            className={classes.social}
            aria-label="email"
          >
            <EmailIcon fontSize="large" />
          </MuiLink>
          <MuiLink
            href="tel:+32479603125"
            className={classes.social}
            aria-label="telephone"
          >
            <PhoneIcon fontSize="large" />
          </MuiLink>
        </span>
        <Hidden xsDown>
          {pathname !== '/' &&
            menuItems.map((node, i) => (
              <MenuButton to={'/' + node.fields.slug} key={node.fields.slug}>
                {node.title}
              </MenuButton>
            ))}
        </Hidden>
        <Hidden smUp>
          <Button onClick={handleClick} aria-label="Menu">
            <MenuIcon color="secondary" fontSize="large" />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{ paper: classes.paper }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            {menuItems.map((node, i) => (
              <MenuItem onClick={handleClose} key={node.fields.slug}>
                <MenuButton to={'/' + node.fields.slug}>
                  {node.title}
                </MenuButton>
              </MenuItem>
            ))}
          </Menu>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Navigation.propTypes = {};

export default Navigation;
