import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  makeStyles,
  Paper,
  GridList,
  GridListTile,
  GridListTileBar,
  Button,
} from '@material-ui/core';
import Reference from './reference';
import Link from './link';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  link: {
    marginTop: theme.spacing(2),
  },
}));

const References = ({ references }) => {
  const classes = useStyles();
  if (!references) return null;
  return (
    <Paper variant="outlined" className={classes.paper}>
      <Typography variant="body2" component="div">
        <h2>References</h2>
      </Typography>
      <div className={classes.root}>
        <GridList className={classes.gridList} cols={2.5}>
          {references.map((reference) => (
            <Reference reference={reference} key={reference.id} />
          ))}
        </GridList>
      </div>

      <Link to="/references">
        <Button className={classes.link}>View all</Button>
      </Link>
    </Paper>
  );
};

References.propTypes = {
  references: PropTypes.array,
};

export default References;
