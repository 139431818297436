import React from 'react';
import { makeStyles, Container, Grid, Typography } from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import Block from '../components/block';
import References from '../components/references';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecorationLine: 'none',
  },
}));

const PageLayout = ({ data }) => {
  const page = data.contentfulPage;
  const classes = useStyles();
  return (
    <Layout>
      <SEO title={page.title} />
      <Container>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={2} sm={1}>
            <Link to="/" className={classes.link} aria-label="home">
              <Typography color="secondary" variant="h3" component="h2">
                &lt;
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={10} sm={11}>
            <Typography color="secondary" variant="h3" component="h1">
              {page.title}
            </Typography>
          </Grid>
        </Grid>
        {page.blocks &&
          page.blocks.map((block) => <Block block={block} key={block.id} />)}
        <References references={page.references} showMore={true} />
      </Container>
    </Layout>
  );
};

export default PageLayout;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPage(fields: { slug: { eq: $slug } }) {
      title
      fields {
        slug
      }
      blocks {
        id
        slug
        restrictInHeight
        text {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 100, format: HTML)
          }
        }
        image {
          fluid(maxWidth: 220) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      references {
        id
        name
        link
        image {
          fixed(height: 200, width: 300) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
  }
`;
