import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Grid container justify="center" alignItems="center">
      <Grid item xs>
        <Typography color="secondary" variant="h1">
          Oops
        </Typography>
      </Grid>
    </Grid>
  </Layout>
);

export default NotFoundPage;
