import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import Img from 'gatsby-image';
import Link from './link';
import { useStaticQuery, graphql } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  cardActionAreaRoot: {
    minHeight: 350,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      minWidth: 310,
    },
  },
  cardContentRoot: {
    paddingBottom: 0,
    width: '100%',
  },
}));

const HomeCard = ({ image, title, description, link }) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      home: allContentfulHomepage(limit: 1) {
        nodes {
          learnMore
        }
      }
    }
  `);
  const homeData = data.home.nodes[0];
  return (
    <Link to={link}>
      <Card className={classes.root}>
        <CardActionArea classes={{ root: classes.cardActionAreaRoot }}>
          <Img fixed={image} style={{ width: '100%' }} />
          <CardContent classes={{ root: classes.cardContentRoot }}>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              <span dangerouslySetInnerHTML={{ __html: description }}></span>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small">{homeData.learnMore}</Button>
        </CardActions>
      </Card>
    </Link>
  );
};

HomeCard.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default HomeCard;
