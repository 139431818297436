import React from 'react';
import { makeStyles, Container, Grid } from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import HomeCard from '../components/homeCard';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
  },
}));

const renderCard = (id, title, description, image, link) => (
  <Grid item sm={6} md={2} key={id}>
    <HomeCard
      title={title}
      description={description}
      image={image}
      link={link}
    />
  </Grid>
);

const Index = ({ data }) => {
  const classes = useStyles();
  const pages = data.allContentfulHomepage.nodes[0].pages;
  return (
    <Layout>
      <SEO title="Home" />
      <Container className={classes.container}>
        <Grid container spacing={3} justify="center">
          {pages.map((page) =>
            renderCard(
              page.id,
              page.title,
              page.description.childMarkdownRemark.html,
              page.homepageImage.fixed,
              '/' + page.fields.slug
            )
          )}
        </Grid>
      </Container>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query {
    allContentfulHomepage(limit: 1) {
      nodes {
        pages {
          id
          title
          fields {
            slug
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          homepageImage {
            fixed(height: 200) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;
